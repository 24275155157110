/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
/* eslint-disable no-new */
/* eslint-disable no-undef */

function lerp(start, end, amt) {
  return (1 - amt) * start + amt * end;
}

class Particle {
  constructor() {
    this.x = 0;
    this.y = 0;
  }
}

class Canvas {
  constructor() {
    const mediaQuery = window.matchMedia('(prefers-reduced-motion: reduce)');
    this.motion = !mediaQuery.matches;
  }

  onWindowResize() {
    window.addEventListener('resize', () => {
      if (this.motion) {
        this.width = this.canvas.parentNode.offsetWidth;
        this.height = this.canvas.parentNode.offsetHeight;

        this.canvas.width = this.width;
        this.canvas.height = this.height;
      }
    });
  }
}

class CanvasMouse extends Canvas {
  constructor(target) {
    super();
    this.canvas = document.querySelector(target);
    this.c = this.canvas.getContext('2d'); // context

    if (this.motion) {
      this.width = this.canvas.parentNode.offsetWidth;
      this.height = this.canvas.parentNode.offsetHeight;
      this.img = new Image();
      this.imgSize = this.width > 768 ? 100 : 80;

      this.count = this.width > 768 ? 45 : 20;
      this.particles = [];

      this.mX = 0;
      this.mY = 0;
      this.delay = this.width > 768 ? 0.65 : 0.45;
      this.isHover = false;
      this.isIDLE = false;
      this.visible = true;
      this.opacity = 1;

      this.img.src = './assets/images/canvas/canvas-logo.png';
      this.img.onload = () => {
        this.init();
      };
    } else {
      this.canvas.parentNode.classList.add('no-motion');
    }
  }

  init() {
    this.canvas.width = this.width;
    this.canvas.height = this.height;

    for (let i = 0; i < this.count; i += 1) {
      const p = new Particle();
      p.x = this.width / 2 - this.imgSize / 2;
      p.y = this.height / 2 - this.imgSize / 2;

      this.particles.push(p);
    }

    this.draw();
    this.onMouseMove();
    this.onWindowScroll();
    super.onWindowResize();
  }

  draw() {
    if (this.visible) {
      this.c.clearRect(0, 0, this.width, this.height);


      if (!this.isIDLE && this.opacity <= 1) {
        this.opacity += 0.1;
      }

      if (this.isIDLE && this.opacity > 0) {
        this.opacity -= 0.1;
      }

      this.c.globalAlpha = this.opacity;

      let x;
      let y;

      if (this.isHover) {
        x = this.mX;
        y = this.mY;
      } else {
        const speed = 0.0004;
        const noiseX = (noise.simplex2(0, Date.now() * speed) + 1) / 2;
        const noiseY = (noise.simplex2(1, Date.now() * speed) + 1) / 2;
        x = noiseX * this.width;
        y = noiseY * this.height;
      }

      this.particles.forEach((p, index) => {
        const nextP = this.particles[index + 1] || this.particles[0];

        p.x = x;
        p.y = y;

        this.c.globalCompositeOperation = 'destination-over';
        this.c.drawImage(this.img, p.x, p.y, this.imgSize, this.imgSize);

        x = lerp(nextP.x, p.x, this.delay);
        y = lerp(nextP.y, p.y, this.delay);
      });
    }

    requestAnimationFrame(() => this.draw());
  }

  onWindowScroll() {
    window.addEventListener('scroll', () => {
      const parent = this.canvas;
      const pos = parent.getBoundingClientRect();

      if (pos.top < window.innerHeight && pos.bottom >= 0) {
        this.visible = true;
      } else {
        this.visible = false;
      }
    });

    setTimeout(() => {
      window.scrollTo(window.scrollX, window.scrollY - 1);
      window.scrollTo(window.scrollX, window.scrollY + 1);
    }, 120);
  }

  onMouseMove() {
    let timer;

    this.canvas.addEventListener('mouseenter', () => {
      this.isHover = true;
      this.isIDLE = false;

      clearTimeout(timer);
    });

    this.canvas.addEventListener('mouseleave', () => {
      this.isIDLE = true;

      timer = setTimeout(() => {
        this.isHover = false;
        this.isIDLE = false;
      }, 600);
    });

    this.canvas.addEventListener('mousemove', (e) => {
      this.mX = (e.offsetX) - (this.imgSize / 2);
      this.mY = (e.offsetY) - (this.imgSize / 2);
      this.isHover = true;
      this.isIDLE = false;
    });
  }
}

class CanvasScroll extends Canvas {
  constructor(target, version) {
    super();
    this.canvas = document.querySelector(target);
    this.c = this.canvas.getContext('2d'); // context
    this.particles = [];
    this.progress = 0;
    this.visible = true;
    this.version = version;

    if (this.motion) {
      this.setSize();
      this.img = new Image();
      this.img.src = './assets/images/canvas/canvas-logo-white.svg';
      this.img.onload = () => {
        this.init();
      };
    } else {
      this.canvas.parentNode.classList.add('no-motion');
    }
  }


  setSize() {
    this.width = this.canvas.parentNode.offsetWidth;
    this.height = this.canvas.parentNode.offsetWidth;
    this.imgSize = this.width > 768 ? this.width / 3 : this.width / 3.5;

    this.count = this.width > 768 ? 40 : 20;
    this.gapX = this.width > 768 ? 12 : 8;
    this.gapY = this.width > 768 ? 0.65 : 0.45;

    this.posX = this.width - this.imgSize * 1.2;
    this.posY = this.height - this.imgSize;

    this.angle = 45;

    this.canvas.width = this.width;
    this.canvas.height = this.width;

    if (this.version === 2) {
      this.count = this.width > 768 ? 35 : 15;
      this.posX = this.width * 0.06 - this.imgSize;
      this.posY = this.height * 0.64 - this.imgSize;
    }

    if (this.version === 3) {
      this.count = this.width > 768 ? 35 : 15;
      this.posX = this.width - this.imgSize * 1.1;
      this.posX = this.width > 768 ? this.width - this.imgSize * 1.1 : this.width - this.imgSize * 1.6;
      this.posY = this.height - this.imgSize * 1.2;
      this.posY = this.width > 768 ? this.height - this.imgSize * 1.2 : this.height - this.imgSize * 1.6;
    }


    this.canvas.parentNode.style.height = `${this.canvas.parentNode.offsetWidth}px`;
  }

  init() {
    for (let i = 0; i < this.count; i += 1) {
      const p = new Particle();
      this.particles.push(p);
    }

    this.draw();
    this.onWindowResize();
    this.onWindowScroll();
  }

  draw() {
    if (this.visible) {
      this.c.clearRect(0, 0, this.width, this.height);

      this.particles.forEach((p, i) => {
        const index = i * this.progress;
        let x = this.posX - index * this.gapX;
        let y = this.posY - index * this.gapY;

        if (this.version === 3) {
          x = this.posX + index * this.gapX;
          y = this.posY + index * this.gapY;
        }


        const cX = x + (this.imgSize / 2);
        const cY = y + (this.imgSize / 2);

        p.x = cX - this.width;
        p.y = cY - this.height;

        if (this.version === 2) {
          p.x = cX - this.width / 3.75;
          p.y = cY - this.height / 2;
        }

        this.c.save();
        this.c.translate(cX, cY);

        if (this.version === 1) {
          this.c.rotate(this.angle + (index * 0.8 * Math.PI / 180));
        }

        if (this.version === 2) {
          this.c.rotate(this.angle * 1.5 + (index * -1 * Math.PI / 45));
        }

        if (this.version === 3) {
          this.c.rotate(this.angle * -1.5 + (index * -0.8 * Math.PI / 45));
        }

        this.c.drawImage(this.img, p.x, p.y, this.imgSize, this.imgSize);
        this.c.restore();
      });
    }
    requestAnimationFrame(() => this.draw());
  }

  onWindowScroll() {
    window.addEventListener('scroll', () => {
      const parent = this.canvas.parentNode.parentNode.parentNode.querySelector('.row');
      const pos = parent.getBoundingClientRect();

      if (pos.top < window.innerHeight && pos.bottom >= 0) {
        this.visible = true;
        const percent = (parent.offsetHeight - pos.top) / 1000;

        if (this.version === 1 && percent <= 2) {
          this.progress = percent;
        }

        if (this.version === 2 && percent <= 2) {
          this.progress = percent * 0.5 + 0.6;
        }

        if (this.version === 3 && percent <= 2) {
          this.progress = percent * 0.35 + 0.6;
        }
      } else {
        this.visible = false;
      }
    });
  }

  onWindowResize() {
    window.addEventListener('resize', () => {
      this.setSize();
    });
  }
}